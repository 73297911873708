var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"dashboard__container--body"},[_c('vue-good-table',{attrs:{"columns":_vm.columnsD,"rows":_vm.eventDrops,"search-options":{
          enabled: true,
          placeholder: 'Search this table',
        },"pagination-options":{
          enabled: true,
          mode: 'records',
          perPage: 20,
        }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'dropped')?_c('span',[(props.row.created)?_c('span',[_vm._v(_vm._s(_vm.formatDate(props.row.created)))]):_vm._e()]):(props.column.field == 'notes')?_c('span',[(props.row.note)?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(props.row.note),expression:"props.row.note"}],staticClass:"icon"},[_c('i',{staticClass:"far fa-sticky-note"})]):_vm._e()]):(props.column.field == 'email')?_c('span',[_c('a',{attrs:{"href":`mailto:` + props.row.email,"target":"_blank"}},[_vm._v(" "+_vm._s(props.row.email)+" ")])]):(props.column.field == 'day')?_c('span',[((props.row.day))?_c('span',[_vm._v(" "+_vm._s(props.row.day)+" ")]):_vm._e()]):(props.column.field == 'fullName')?_c('span',[_c('router-link',{attrs:{"to":'/users/' + props.row.userId}},[_vm._v(" "+_vm._s(props.row.fullName)+" ")])],1):(props.column.field == 'phone')?_c('span',[(props.row.phone)?_c('span',[_c('a',{attrs:{"href":'sms:' + props.row.phone}},[_vm._v(_vm._s(props.row.phone))])]):_vm._e()]):_c('span')]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }