<template>
  <div>
    <div class="dashboard__container--body" >
    
      <vue-good-table
          :columns="columnsD"
          :rows="eventDrops"
          :search-options="{
            enabled: true,
            placeholder: 'Search this table',
          }"
          :pagination-options="{
            enabled: true,
            mode: 'records',
            perPage: 20,
          }"
        >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'dropped'">
            <span v-if="props.row.created">{{formatDate(props.row.created)}}</span>
          </span>
          <span v-else-if="props.column.field == 'notes'">
            <button class="icon" v-if="props.row.note" v-tooltip="props.row.note">
              <i class="far fa-sticky-note"></i>
            </button>
          </span>
          <span v-else-if="props.column.field == 'email'">
            <a :href="`mailto:` + props.row.email" target="_blank">
              {{props.row.email}}
            </a>
          </span>
         
          
        
          <span v-else-if="props.column.field == 'day'">
            <span v-if="(props.row.day)">
              {{props.row.day}}
            </span>
          </span>
          
          <span v-else-if="props.column.field == 'fullName'">
            <router-link :to="'/users/' + props.row.userId">
              {{props.row.fullName}}
            </router-link>
          </span>
         
          <span v-else-if="props.column.field == 'phone'">
            <span v-if="props.row.phone">
              <a :href="'sms:' + props.row.phone">{{props.row.phone}}</a>
            </span>
          </span>
          <span v-else>
            <!-- {{props.formattedRow[props.column.field]}} -->
          </span>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>

<style scoped type="text/css">
  .darkLink {
    color:#606266;
  }
  .darkLink:hover {
    color:#fc3ef9;
  }
</style>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import * as moment from 'moment'
import router from '@/router'
import firebase from 'firebase/app';
const fb = require('../../../firebaseConfig.js')

export default {
  props: ['eventInfo'],
  name: 'staffingDrops',
  data() {
    return {
      columnsD: [
        {
          label: 'Name',
          field: 'fullName',
        },
        {
          label: 'Phone',
          field: 'phone',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Day to Work',
          field: 'day',
        },
        {
          label: 'Dropped',
          field: 'dropped',
          sortable: false,
        },
        {
          label: 'Notes',
          field: 'notes',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
    }
  },
  created () {
    if (!this.eventDrops || this.eventDrops.length < 1) {
      this.$store.dispatch("getEventDrops", this.$route.params.id)
    }
  },
  components: {
    Loader,
  },
  computed: {
    ...mapState(['currentUser', 'eventDrops', 'userProfile']),
    event() {
      return this.eventInfo
    },
  },
  methods: {
    formatAMPM(date) {
      console.log(date)
      if (typeof date === "string") {
        let [hours, minutes] = date.split(":");
        let ampm = "AM";

        if (Number(hours) >= 12) {
          hours = Number(hours) - 12;
          ampm = "PM";
        }

        return `${hours}:${minutes} ${ampm}`;
        console.log(`${hours}:${minutes} ${ampm}`)
      } else if (date instanceof Date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();

        let ampm = hours >= 12 ? "PM" : "AM";

        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? "0" + minutes : minutes;

        let strTime = hours + ":" + minutes + " " + ampm;

        return strTime;
      }
      return date;
    },
    formatDate(q) {
      if(q) {
        const postedDate = new Date(q.seconds) * 1000;
        return moment(postedDate).format('MMM Do YYYY')
      } else {
        return null
      }
    },
  },
  destroyed () {
    this.$store.dispatch("clearEventDrops")
    
    this.columnsD = null
    delete this.columnsD
    delete this.formatDate
    delete this.formatAMPM
    console.log(this)
  }
}
</script>